<template>
  <section class="section-about">
    <about-banner :headerHeight="295" :detailPage="true" />
    <template>
      <section class="py-sm-16 py-8">
        <v-container fluid class="px-lg-10 py-lg-16 py-lg-10">
          <v-row justify="center" class="px-lg-16">
            <v-col class="col-10">
              <p
                class="text-lg-justify text-center px-lg-16 font-weight-light mb-0"
                v-html="$t('about.aboutUsDescription1')"
              ></p>
            </v-col>
          </v-row>
          <v-row justify="center" class="px-lg-16">
            <v-col class="col-10">
              <p
                class="text-lg-justify text-center px-lg-16 font-weight-light mb-0"
                v-html="$t('about.aboutUsDescription2')"
              ></p>
            </v-col>
          </v-row>
        </v-container>
      </section>
      <section class="py-sm-16 py-8 px-lg-16 odd">
        <v-container fluid class="px-lg-16 pt-lg-16 pt-lg-10">
          <v-row class="px-16 pb-lg-16 pb-8" justify="center">
            <h3
              class="text-center text-lg-h3 text-h5 font-weight-bold"
              :style="{ color: $routeMeta.meta.active.color }"
            >
              {{ $t("about.ourTeam") }}
            </h3>
          </v-row>
          <v-row justify="center" class="px-lg-16 px-10 pt-lg-8">
            <v-col
              cols="12"
              xs="12"
              lg="3"
              sm="6"
              class="pa-0 mt-8 mt-lg-16 px-sm-10 px-lg-0"
              v-for="item in memberData"
              :key="item.id"
            >
            <v-container>
              <img
                lazy
                :src="item.image_url"
                :height="171"
                class="ma-auto rounded-circle shadow d-flex"
                width="171"
              />
            </v-container>
              <v-container>
                <v-row class="pt-4 justify-center">
                  <h3 class="text-center text-name">
                    {{ item.name }}
                  </h3>
                </v-row>
                <v-row class="ma-0 justify-center pt-3">
                  <p class="text-center text-position font-weight-regular">
                    {{ $t(item.cargo[locale]) }}
                  </p>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </section>
      <section>
        <v-container fluid class="pa-0 mb-lg-n10">
          <v-img
            :eager="true"
            lazy-src="/servicios.svg"
            src="/banners/download_background.svg"
            :min-height="353"
            :max-height="353"
            class="headImage ma-0 pa-0 align-center"
            v-ripple
          >
            <v-container class="portfolio-download">
              <v-row justify="center">
                <p
                  class="text-white text-h5 font-weight-bold font-italic text-center"
                >
                  {{ $t("about.portfolioDownload") }}
                </p>
              </v-row>
              <v-row justify="center" class="">
                <a :href="$t('about.portfolio')" download>
                  <v-btn
                    elevation="0"
                    class="rounded-lg font-weight-bold"
                    width="175"
                  >
                    {{ $t("about.btnDownload") }}
                  </v-btn>
                </a>
              </v-row>
            </v-container>
          </v-img>
        </v-container>
      </section>
    </template>
  </section>
</template>
<script>
import serviceMember from '../services/members'
export default {
  name: "About",
  metaInfo() {
    return {
      title: this.$t("about.ourTeam"),
      meta: [
        { name: "description", content: this.$t("about.aboutUsDescription1") },
        { name: "description", content: this.$t("about.aboutUsDescription2") }
      ]
    };
  },
  data: () => ({
    memberData:[],
    team: [
      {
        src: "/team/YoelLeonardo.png",
        name: "Yoel Leonardo",
        text: "about.founder"
      },
      {
        src: "/team/JennyCampechano.png",
        name: "Jenny Campechano",
        text: "about.coFounder"
      },
      {
        src: "/team/AdderlyPolanco.png",
        name: "Adderly Polanco",
        text: "about.frontEndDeveloper"
      },
      {
        src: "/team/ErickJimenez.png",
        name: "Erick Jiménez",
        text: "about.fullStack"
      },
      {
        src: "/team/MelissaSantos.png",
        name: "Melissa Santos",
        text: "about.directorOfArts"
      },
      {
        src: "/team/Anthony.png",
        name: "Anthony Taveras",
        text: "about.fullStack"
      },
      {
        src: "/team/Sandy.png",
        name: "Sandy García",
        text: "about.scrumMaster"
      },
      {
        src: "/team/DariannyPeguero.png",
        name: "Darianny Peguero",
        text: "about.juniorDevelop"
      },
      {
        src: "/team/Geffry.png",
        name: "Geffry González",
        text: "about.dataAnalyst"
      },
      {
        src: "/team/Rudy.png",
        name: "Rudy Cordero",
        text: "about.graphicDesigner"
      },
      {
        src: "/team/Laura.png",
        name: "Laura Ortiz",
        text: "about.adsDirector"
      },
      {
        src: "/team/Alira.png",
        name: "Alira Peña",
        text: "about.graphicDesignerF"
      }
    ]
  }),
  created() {
    const country = localStorage.getItem("country");
    if (country) {
      const parsedLocale = JSON.parse(country)
      this.locale = parsedLocale.locale
      console.log(this.locale)
    }
  },
  mounted: async function(){
    const resultMember = await serviceMember.getMembers();
    this.memberData = resultMember.data;
    console.log(this.memberData)
  }
};
</script>
